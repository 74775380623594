import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { plPL } from '@mui/x-data-grid/locales';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import useFetch from "../../service/api/getAPI";
import { REACT_APP_API_URL } from "../../env";


const Action = () => {

    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const { data: actions, loading, error } = useFetch(REACT_APP_API_URL + "Actions/GetByActionsTime?fromDate=" + (selectedDateRange[0] ? selectedDateRange[0].toLocaleDateString() : '') + "&toDate=" + (selectedDateRange[1] ? selectedDateRange[1].toLocaleDateString() : ''));

    useEffect(() => {
        console.log(actions);
    }, [actions]);

    if(loading) return <Loading />;
    if(error?.length > 0) return <Error err={error} />;

    // Calculate totals if actions are available
    let totalCountActions = 0;
    let totalTime = 0;
    if (actions.length > 0) {
        totalCountActions = actions.reduce((acc, curr) => acc + curr.countActions, 0);
        totalTime = actions.reduce((acc, curr) => acc + curr.time, 0);
    }

    // Create a new object for the totals if actions are available
    const totals = actions.length > 0 ? {
        id: "totals",
        countActions: totalCountActions,
        time: totalTime,
        ozsFullName: " "
    } : null;

    // Append totals to the actions array if available
    const rowsWithTotals = actions.length > 0 ? [...actions, totals] : [];

    const columns = [
        { field: "countActions", headerName: "Ilość zamkniętych zgloszeń", flex: 1, cellClassName: "name-column--cell", valueGetter: (e) => e},
        { field: "time", headerName: "Czas zaraportowany pod zgłoszeniami [h]", flex: 1, valueGetter: (e) => e },
        { field: "ozsFullName", headerName: "OZS", flex: 1, valueGetter: (e) => e },
    ];

    const handleDateRangeChange = (value) => {
        setSelectedDateRange(value);
    };

    const getRowClassName = (params) => {
        return params.id === 'totals' ? 'totals-row' : '';
    };

    const getCellClassName = (params) => {
        return params.row.id === 'totals' ? 'totals-cell' : '';
    };

    return(
        <Box margin="20px">
            <Box display="flex" justifyContent="space-between">
                <Typography style={{color: "#ed1111", fontFamily: "Bold" }} variant="h3">Tkomp </Typography>
                <Typography style={{color: "#8c8b8b", fontFamily: "Bold", marginTop: "12px" }} variant="h4">Raport z działań na serwisie  </Typography>
            </Box>
            <Box>
                <DateRangePicker
                    style={{marginTop: "50px"}}
                    value={selectedDateRange}
                    onChange={handleDateRangeChange}/>
            </Box>
            <Typography style={{color: "#a8a8a8", marginLeft: "5px" }} variant="p">Wybrany zakres dat: {selectedDateRange[0] && selectedDateRange[0].toLocaleDateString()} - {selectedDateRange[1] && selectedDateRange[1].toLocaleDateString()}  </Typography>
            <Box marginTop="30px">
                <Box margin="40px 0px 0px 0px" sx={{
                    "& .MuiDataGrid-root": {},
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                        fontSize: '1rem',
                        textAlign: 'center' // Center align text
                    },
                    "& .name-column--cell": {
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#e3dcdd", // Red color for column headers
                        borderBottom: "none",
                        fontSize: '1.1rem'
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: "#f5f5f5"
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: "#e3dcdd"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${"#e0e0e0"} !important`,
                        fontSize: '1rem',
                    },
                    "& .totals-row": {
                        backgroundColor: "#e8e6e6", 
                    },
                    "& .totals-cell": {
                        color: "#ed1111", // Red color
                    }
                }}>
                    <DataGrid 
                        rows={rowsWithTotals} 
                        columns={columns} 
                        components={{ Toolbar: GridToolbar }} 
                        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                        getRowClassName={getRowClassName}
                        getCellClassName={getCellClassName}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export default Action;
